<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :loading="loading"
        :table-config="tableConfig"
        :search-config='searchConfig'
        :table-data="tableData"
        :total="total"
        @onChangePage="changeCurrentPage"
        @onReset="search = {}"
        @onSearch="onSearch"
        @onExportAll="onDownload">
    </list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  _config:{
    route:{
      path:"list",
      meta:{"title":"列表","keepAlive":true},

    }
  },
  data() {
    return {
      loading: true,
      // 表格搜索配置
      searchConfig: [{
        prop: 'teacher_name',
        placeholder: '搜索教师姓名'
      }, {
        prop: 'start_date',
        tag: 'datePicker',
        property: {
          size: 'small',
          style: 'margin-right:20rem;',
          valueFormat: 'yyyyMM',
          type: 'monthrange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
        },
      }, {
        prop: 'school_id',
        placeholder: '请选择校区',
        tag: 'select',
        options: [],
        value: 'id',
        label: 'school_name',
        change: (val, search) => {
          delete search['grade_id']
        }
      }, {
        prop: 'grade_id',
        placeholder: '请选择年级',
        tag: 'select',
        options: [],
        value: 'id',
        label: 'name'
      },],
      // 表格配置
      tableConfig: [
        {prop: 'user_name', label: '教师姓名'},
        {prop: 'date', label: '统计月份'},
        {prop: 'need', label: '应打卡总次数'},
        {prop: 'hit', label: '已打卡次数'},
        {prop: 'grade', label: '年级'},
        {prop: 'school', label: '校区'},
      ],
      search: {},
      total: 0,
      // 表格中的数据
      tableData: [],
    }
  },
  computed: {
    ...mapState(["page"])
  },
  mounted() {
    this.$store.commit("setPage", 1);
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage", e);
      this.getData()
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this
      let searchConfig = JSON.parse(JSON.stringify(search))
      if (JSON.stringify(searchConfig) !== '{}') {
        if (searchConfig.start_date) {
          searchConfig.start_date = search.start_date[0].replace(/(\d{4})(\d{2})/, '$1-$2')
          searchConfig.end_date = search.start_date[1].replace(/(\d{4})(\d{2})/, '$1-$2')
        }
      }
      this.loading = true;
      this.$_axios2.get('api/attendance/teacher-attendance/day-thrice', {params: {...searchConfig, page}}).then(res => {
        let {data} = res.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit("setPage", 1);
      this.search = val
      this.getData()
    },
    onDownload() {
      let {search} = this
      let searchConfig = JSON.parse(JSON.stringify(search))
      console.log(searchConfig);
      if (JSON.stringify(searchConfig) !== '{}') {
        if (searchConfig.start_date) {
          searchConfig.start_date = search.start_date[0].replace(/(\d{4})(\d{2})/, '$1-$2')
          searchConfig.end_date = search.start_date[1].replace(/(\d{4})(\d{2})/, '$1-$2')
        }
      }
      this.$_axios2.get('api/attendance/teacher-attendance/day-thrice-exporter', {params: {...searchConfig}}).then(res => {
        this.$tools.download('三到岗统计', res.data)
      })
    }
  },
  created() {
    this.$_axios2('api/pre-course-select/options/schools').then(res => {
      this.searchConfig[2].options = res.data.data
    })
    this.$_axios2('api/pre-course-select/options/grades').then(res => {
      this.searchConfig[3].options = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-range-separator {
  width: 24rem !important;
}
</style>
